<!--
 * 盲盒详情页
-->

<template>
  <div>
    <div class="seting-box">
      <div class="">
        <div class="head-bg">
          <!-- <van-image width="100vw" :src="img1" http://testresource.mhxk.com/nft_audit_image/Screenshot_20220923_170605_com.wbxm.icartoon.jpg  /> -->
          <van-image width="100vw" :src="productDetail.image_bg" />
        </div>
      </div>
      <div class="meng"></div>
      <div class="content">
        <div class="next-box" @click="next">
          <div class="next" v-if="allCard.length > 1">next <van-icon name="arrow" size="14" /></div>
        </div>
        <div class="prudct-box">
          <div class="prudct">
            <div class="name-box">
              <span class="f-20 f-w">{{ productDetail.items_name }}</span>
              <img class="wid73" :src="levelsChange(productDetail.levels)" alt="" />
            </div>
            <div class="f-10">本期发售{{ productDetail.total_num }}份</div>

            <!-- <div class="num-box">
            <span class="f-9 num-lable">{{ box.is_pre_sale == 1 ? '预售' : '限量' }}</span>
            <span class="f-9 num-value">{{ productDetail.total_num }}份</span>
          </div> -->
          </div>
          <div class="f-12 app">
            <img class="icon-logo" src="@/imgs/icon/ico-kmh.png" alt="" />
            <span class="primary-color l-h" @click="appclick">{{ productDetail.publisher }}</span
            >发行
          </div>
          <div class="f-12 c-07">
            {{ productDetail.description }}
          </div>
        </div>
        <!-- 戮天戟发售 -->
        <div class="" v-if="box.id == 38">
          <div class="newItem38">
            <div class="desc" @click="showrule = true">说明</div>
            <img src="https://activity-resource.321mh.com/nft/notice/lutianji_sell.png" alt="" />
          </div>
        </div>
        <!-- 神马 -->
        <div class="" v-if="box.id == 42">
          <div class="newItem42">
            <img src="https://activity-resource.321mh.com/nft/notice/guoqing_buy_time.jpg" alt="" />
          </div>
        </div>

        <!-- 抽签流程 -->
        <div class="drawBox" v-if="box.is_draw_lots == 1">
          <div class="drawKnow">
            <div class="titlePic t-c">
              <img src="../imgs/comm/drawStepTitle.png" />
              <div class="desc" @click="showrule = true">说明</div>
            </div>
            <div>
              <div>
                <div class="stepItem">
                  <div class="stepTitle">创世快照</div>
                  <div class="stepIconBox">
                    <img class="stepIcon" src="../imgs/comm/stepIcon.png" />
                  </div>
                  <div class="stepTime">8月17日11:00</div>
                </div>
              </div>
              <div>
                <div class="stepItem">
                  <div class="stepTitle">创世开售</div>
                  <div class="stepIconBox">
                    <img class="stepLine" src="../imgs/comm/stepline.png" />
                    <img class="stepIcon" src="../imgs/comm/stepIcon.png" />
                  </div>
                  <div class="stepTime">8月17日12:00-13:59</div>
                </div>
              </div>
              <div>
                <div class="stepItem">
                  <div class="stepTitle">指定藏品优先购特权开售</div>
                  <div class="stepIconBox">
                    <img class="stepLine" src="../imgs/comm/stepline.png" />
                    <img class="stepIcon" src="../imgs/comm/stepIcon.png" />
                  </div>
                  <div class="stepTime">8月17日14:00-15:59</div>
                </div>
              </div>
              <div v-for="(item, index) in box.lots_times" :key="index">
                <div class="stepItem" v-if="index < 3">
                  <div class="stepTitle">
                    {{ item.type == 1 ? '报名抽签' : item.type == 2 ? `抽签` : `开售` }}
                  </div>
                  <div class="stepIconBox">
                    <img class="stepLine" src="../imgs/comm/stepline.png" />
                    <img class="stepIcon" src="../imgs/comm/stepIcon.png" />
                  </div>
                  <div class="stepTime">
                    {{ getTime(item.start_time) }} -{{ getTime(item.end_time) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 藏品数量公示 -->
        <div class="countBox">
          <div class="countPic t-c">
            <img src="../imgs/comm/countBg.png" />
            <van-icon
              class="questionIcon"
              name="question-o"
              size="0.45rem"
              @click="popShow = true"
            />
          </div>
          <div>
            <div
              class="countItem"
              v-for="(item, index) in countList.length > 2 && !lookMoreFlag
                ? countList.slice(0, 2)
                : countList"
              :key="index"
            >
              <div class="name">{{ item.name }}</div>
              <div class="num">{{ item.num }}</div>
            </div>
          </div>
          <!-- 展开/收起 -->
          <div class="lookMore" @click="lookMore">
            <div>{{ lookMoreFlag ? '收起' : '点击查看更多' }}</div>
            <img
              :class="lookMoreFlag ? 'lookMoreActive' : ''"
              src="../imgs/point/icon-right.png"
              alt=""
              srcset=""
            />
          </div>
        </div>

        <!-- <div class="">
        <div class="image-box" v-for="(item, index) in contents" :key="index">
          <van-image width="100%" :src="item"></van-image>
        </div>
      </div> -->

        <div class="card-box" v-if="box.detail_type == 1">
          <div class="t-c" v-for="(item, index) in contents" :key="index">
            <img :src="item" :class="index == 0 ? 'card-msg' : 'imgcard'" alt="" />
          </div>
          <!-- <div class="t-c">
          <img :src="productDetail.image_icon" class="imgcard" alt="" />
        </div> -->
        </div>
        <div v-if="box.detail_type == 2" style="margin-bottom: 10px">
          <img
            style="width: 100%"
            :src="item"
            v-for="(item, index) in JSON.parse(box.contents)"
            :key="index"
            alt=""
            srcset=""
          />
        </div>

        <div class="brigt">
          <div class="">
            <div class="t-c">
              <img class="wid237" :src="require('../imgs/comm/Slice15.png')" alt="" />
            </div>
            <div class="t-c f-10 f-0 brigt-content c-07">
              本期{{ productDetail.items_name }}{{ box.is_pre_sale == 1 ? '预售' : '' }}发行数量为{{
                productDetail.total_num
              }}份
            </div>
            <div class="">
              <div class="flex-bt marg36">
                <img class="wid90" :src="require('../imgs/comm/Slice20.png')" alt="" />
                <img class="wid90" :src="require('../imgs/comm/Slice21.png')" alt="" />
              </div>
              <div class="flex-bt">
                <img class="wid90" :src="require('../imgs/comm/Slice22.png')" alt="" />
                <img class="wid90" :src="require('../imgs/comm/Slice23.png')" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="parameter f-12">
          <div class="">
            <div class="title t-c">
              <img class="wid237" :src="require('../imgs/comm/Slice16.png')" alt="" />
            </div>
            <div class="bright-item">
              <span class="c-07">认证标识</span>
              <span> 已上链</span>
            </div>
            <div class="bright-item">
              <span class="c-07">认证标准</span>
              <span> ERC1155</span>
            </div>
            <div class="bright-item">
              <span class="c-07">认证网络</span>
              <span> {{ productDetail.token_network }}</span>
            </div>
            <div class="bright-item">
              <span class="c-07">商品名称</span>
              <span> {{ productDetail.items_name }}</span>
            </div>
            <div class="bright-item">
              <span class="c-07">商品类型</span>
              <span> {{ productDetail.type_name }}</span>
            </div>

            <div class="bright-item">
              <span class="c-07">品牌方</span>
              <span> {{ productDetail.producter }}</span>
            </div>
            <div class="bright-item">
              <span class="c-07">发行方</span>
              <span> {{ productDetail.publisher }}</span>
            </div>
            <div class="bright-item" v-if="productDetail.publish_date">
              <span class="c-07">发行时间</span>
              <span> {{ timeChange(productDetail.publish_date) }}</span>
            </div>
            <div class="bright-item">
              <span class="c-07">商品编号</span>
              <span> {{ productDetail.series_num }}</span>
            </div>
            <!-- <div class="bright-item">
            <span class="c-07">铸造数量</span>
            <span> {{ productDetail.token_num }}</span>
          </div> -->
          </div>
        </div>

        <!-- 购买须知 -->
        <!-- <div class="bgBox">
          <div class="buyKnow">
            <div class="titlePic t-c">
              <img src="../imgs/comm/buyKnowTitle.png" />
            </div>
            <div class="text">
              1、数字藏品是一种虚拟数字商品，
              市场价格可能会存在较大的波动，风险承受能力在理性判断后再购买数字藏品。<br />
              2、不支持未成年和在校学生购买数字藏品，如果您属于此类身份，请不要购买数字藏品。<br />
              3、数字藏品是艺术藏品，欢迎鉴赏者持有收藏，非投资或理财产品，不具备任何金融性质，看漫app不支持任何炒卖，金融化行为。<br />
              4、鉴于数字藏品的特殊性质，一旦购买成功将无法退换。<br />
            </div>
          </div>
        </div> -->
      </div>
      <kmh-sheet v-model:show="showDetail" @close="showDetail = false"> </kmh-sheet>

      <div class="btnbox">
        <!-- <div class="btn_bg">{{productDetail.status==2?"已售罄":"抽盲盒"}}</div> -->
        <BoxBtn
          v-if="box.is_draw_lots == 1 && box.pre_type && box.lots_info.status_type <= 0"
          :status="box.status"
          :time="box.start_time"
          :is_pre_sale="box.is_pre_sale"
          :total_num="box.total_num"
          @finistTime="getDetail(collections_id, id)"
          @click="goToDetail(box.start_time)"
          :presale_start_time="box.presale_start_time"
          :presale_end_time="box.presale_end_time"
          :is_advance="box.is_advance"
          :pre_type="box.pre_type"
          :type="box.type"
          :server_time="server_time"
        />
        <boxDraw
          v-else-if="box.is_draw_lots == 1"
          @getList="getBox"
          :itemData="box"
          :collections_id="collections_id"
          @drawLuckyBug="goToDetail(box.start_time)"
        />
        <BoxBtn
          v-else
          :status="box.status"
          :time="box.start_time"
          :is_pre_sale="box.is_pre_sale"
          :total_num="box.total_num"
          @finistTime="getDetail(collections_id, id)"
          @click="goToDetail(box.start_time)"
          :presale_start_time="box.presale_start_time"
          :presale_end_time="box.presale_end_time"
          :is_advance="box.is_advance"
          :type="box.type"
          :server_time="server_time"
        />
      </div>
      <PageLoading :show="loading" />
      <div class="" v-if="showPayTyoe">
        <PayType
          v-model:show="showPayTyoe"
          :box="box"
          :payStatus="payStatus"
          @close="showPayTyoe = false"
        />
      </div>

      <OpenTheBox v-if="showOpenBox" @close="showOpenBox = false" :order="order" />
      <PayCbNew v-if="showNewBox" :order="order" />
      <!-- 说明弹窗 -->
      <van-popup class="vantPop" v-model:show="popShow">
        <div class="failPopup">
          <div class="head">说明</div>
          <div class="descContent">
            <div>铸造总量：上链铸造的总数量，不会增发；</div>
            <div>当前流通：当前累计市面流通该藏品的总数量；</div>
            <div>累计发售：当前累计发售的总数量；</div>
            <div>累计销毁：当前累计用于分解和合成的总数量；</div>
            <div>累计赠送：当前累计通过活动赠送、积分抽奖、兑换的总数量；</div>
            <div>累计合成：当前累计通过合成方式发行的总数量；</div>
            <div>系统余量：藏品总铸造数量减去当前发售、销毁、赠送、合成后剩余的总数量。</div>
          </div>
          <div class="btn">
            <div @click="popShow = false">我已知晓</div>
          </div>
        </div>
      </van-popup>

      <!-- 规则说明 -->
      <van-action-sheet v-model:show="showrule" :round="false" style="background-color: #1c172a">
        <div class="header">
          <img src="@/imgs/comm/pic_tc_zs.png" class="img-zs" />
          <van-icon
            :name="icon_tc_gb"
            size="0.427rem"
            class="icon_tc_gb"
            @click="showrule = false"
          />
          <img src="@/imgs/comm/pic_tc_title.png" class="img-title" />
          <span>说明</span>
        </div>
        <div class="block"></div>
        <div class="content-van">
          <div class="nei" v-if="box.id == 38">
            1、9月21日12:00 对「创世勋章」「荒古大帝」「东荒女帝」藏品进行快照
            <br /><br />2、优先购： <br />
            ①每持有一个「创世勋章」可优先购3份 <br />
            ②每持有一个「荒古大帝」可优先购2份 <br />
            ③每持有一个「东荒女帝」可优先购2份 <br />
            ④每持有一个「指定优先购特权」可优先购1份 <br />*持有多个相同或不同藏品时，权益可叠加。
            <br />
            *按照以下顺序依次使用优先购资格：「创世勋章」>「荒古大帝」>「东荒女帝」>「指定优先购特权」
            <br />
            <br />
            3、普通购： <br />
            ①若优先购期间藏品售罄，则普通购数量为0。 <br />
            ②优先购的用户任然可以参与普通购。 <br />
            ③普通购不限购买数量。 <br />
            <br />
            <p class="t-c">最终解释权归看漫所有</p>
          </div>
          <div class="nei" v-else>
            <div class="nei-title">购买方式</div>
            1、创世勋章优先购：每个「创世勋章」可购买2份（只可在创世开售期间购买），权益可叠加。
            <br /><br />
            2、指定藏品优先购特权：每个「指定藏品优先购特权」可购买1份（只可在「指定藏品优先购特权」开售期间购买），权益可叠加。
            <br /><br />
            3、抽签购：通过抽签方式获得购买资格，如果未使用1、2中的权益购买，则中签后可购买1份。
            <br /><br />
            <div class="nei-title">抽签说明</div>

            1、抽签最多两轮，第一轮人人可参与。第二轮抽签的用户仅限第一轮未中签的用户，第一轮中签的用户（无论是否购买藏品）和未参与抽签的用户不可参与。<br /><br />
            2、抽签时间截止后：中签用户可购买新发行的数字藏品，每人限购1份。如果第一轮藏品售罄，则不会进行第二轮抽签；如果第一轮有中签的用户放弃购买，则会在第一轮售卖结束后的十分钟内进行第二轮抽签。
            <br />
            <br />
            3、如果使用「创世勋章」或「指定藏品优先购特权」购买过，则不可报名抽签。<br /><br />
            <div class="nei-title">助力说明</div>
            1、参与抽签后，可邀请好友助力以提升中签概率，最多可邀请3人，助力越多，中签概率越大。<br /><br />
            2、每位用户最多可为他人助力一次，且不可给自己助力。 <br /><br />
            3、第二轮抽签结束后即刻开启售卖，无助力环节。
            <br />
            <br />
            <p class="t-c">最终解释权归看漫所有</p>
          </div>
        </div>
        <!-- <div class="block"></div> -->
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, getCurrentInstance, onBeforeUpdate } from 'vue';
import { useRouter } from 'vue-router';
import { boxDetail, produceDetail, checkorderispay } from '@/service/home';
import moment from 'moment';
import kmhSheet from '@/components/KmhSheet';
import PayType from '@/components/newPayType';
import { localStorageUtil, util, nftUtils, domUtil } from '../utils';
import { pay } from '@/service/order';
import { Toast } from 'vant';
import BoxBtn from '../components/BoxBtn.vue';
import PageLoading from '@/components/loading/PageLoading.vue';
import { shareHelper } from '@/helpers';
import { useStore } from 'vuex';
import OpenTheBox from '@/components/OpenTheBox.vue';
import boxDraw from '@/components/BoxBtnDraw';
import PayCbNew from '@/components/PayCbNew.vue';
export default {
  components: {
    kmhSheet,
    BoxBtn,
    PageLoading,
    PayType,
    OpenTheBox,
    boxDraw,
    PayCbNew
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const route = proxy.$route;
    const state = reactive({
      popShow: false,
      countList: [],
      lookMoreFlag: false,
      productDetail: {},
      img1: 'https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1652341341261.jpg',
      brightList: [1, 2, 3, 4, 5],
      allCard: [], // 所有人物数据
      cardIndex: 0, // 卡片id
      contents: [], //详情
      showDetail: false,
      collections_id: null,
      id: null,
      getCard: true,
      loading: true,
      box: {}, //合集详情
      showPayTyoe: false,
      isVerify: false,
      showOpenBox: false, // 是否显示开盲盒弹窗
      order: '',
      payStatus: 1, //1--正常支付，2--预付款
      showrule: false,
      server_time: 0,
      showNewBox: false
    });
    onBeforeUpdate(async () => {
      if (localStorageUtil.get('orderId')) {
        // 判断是否有已经支付成功的订单
        let trade_no = localStorageUtil.get('orderId');
        // 是新人盲盒
        let isNew = localStorageUtil.get('isNew') ? localStorageUtil.get('isNew') : 0;
        localStorageUtil.del('isNew');
        const { data } = await checkorderispay({ trade_no });
        localStorageUtil.del('orderId');
        if (data) {
          state.order = trade_no;
          if (isNew == 1) {
            // 弹窗--新人盲盒
            // state.showNewBox = true;
          } else {
            state.showOpenBox = true;
          }
          // router.push({ path: `/boxopen/${trade_no}` });
        }
      }
      if (localStorageUtil.get('refresh')) {
        localStorageUtil.del('refresh');
        state.showBtn = false;
        setTimeout(() => {
          getBox();
        }, 500);
      }
    });
    const getBox = async () => {
      const { data } = await boxDetail({ id: state.collections_id });
      state.box = data;
      console.log('33', state.box);
    };
    onMounted(async () => {
      // localStorageUtil.set('orderId', 20220609112804288);
      let collections_id = route.params.id.split('&')[0];
      state.collections_id = collections_id;
      let id = route.params.id.split('&')[1];
      state.id = id;
      getDetail(collections_id, id);

      // 获取是否认证
      let isLogin = nftUtils.isLogin();
      if (isLogin) {
        await store.dispatch('refreshLoginInfo');
        state.isVerify = store.state.loginInfo ? !!store.state.loginInfo.id_verify : false;
      }
      const { data } = await boxDetail({ id: state.collections_id });
      state.box = data;
      console.log('state.box', state.box);
      const shareConf = {
        title: '看漫-数字藏品',
        content: data.collections_name + '数字藏品限时限量抢购中...',
        image: '',
        link: location.href, // app分享链接
        callback: () => {
          // console.log('分享成功！！！');
          // Toast("分享成功！");
        }
      };
      // 设置微信分享和app内部分享(shareInfo：为动态获取数据。如果是静态的分享，则直接在data初始化中设置)
      shareHelper.init(shareConf);
      // 设置index.html中的头部分享信息
      domUtil.setDomShareMeta(shareConf);
    });
    const getDetail = async (collections_id, id) => {
      const { data, server_time } = await produceDetail({ collections_id });

      state.allCard = [];
      data.forEach(item => {
        if (item.reward_type == 1) {
          state.allCard.push(item);
        }
      });
      // console.log(state.allCard, '.../// state.allCard');
      state.server_time = server_time;
      state.loading = false;
      window.scroll(0, 0);
      data.map((item, index) => {
        if (id == item.id) {
          console.log(item);
          state.productDetail = item;
          state.cardIndex = index;

          state.countList = [
            {
              name: '铸造总量',
              num: item.token_num
            },
            {
              name: '当前流通',
              num: item.active_num
            },
            {
              name: '累计发售',
              num: item.sell_num
            },
            {
              name: '累计销毁',
              num: item.destory_num
            },
            {
              name: '累计赠送',
              num: item.gift_num
            },
            {
              name: '累计合成',
              num: item.merge_num
            },
            {
              name: '系统余量',
              num: item.remain_num
            },
            {
              name: '更新时间',
              num: timeChange(item.stat_time, 'YYYY-MM-DD HH:mm')
            }
          ];
        }
      });
      state.contents = JSON.parse(state.productDetail.contents);
    };
    const next = () => {
      // next
      nftUtils.setcnzzRecordByBtn('单品详情查看下一个人物');
      if (state.cardIndex == state.allCard.length - 1) {
        state.cardIndex = 0;
      } else {
        state.cardIndex += 1;
      }
      state.productDetail = state.allCard[state.cardIndex];
      state.contents = JSON.parse(state.productDetail.contents);
      // console.log(state.contents);
    };
    const goToDetail = async showTime => {
      // 判断是否登陆
      let now = new Date().getTime();
      nftUtils.setcnzzRecordByBtn('单品详情抽盲盒');
      if (!localStorageUtil.get('accessToken')) {
        router.push({ path: `/login` });
        return;
      }
      if (!state.isVerify) {
        Toast('需要实名认证后才能购买');
        setTimeout(() => {
          router.push({ path: `/authentication` });
        }, 1000);
        return;
      }
      if (state.box.status == 2) {
        Toast(state.box.is_pre_sale == 1 ? '预售结束' : '已结束');
        return;
      }
      if (diffTime(showTime) > 0) {
        // 是否在支付预付款时间段
        if (
          !(
            moment(state.box.presale_start_time).valueOf() < now &&
            now < moment(state.box.presale_end_time).valueOf()
          ) ||
          state.box.is_advance
        ) {
          Toast('未到开售时间');
          return;
        } else {
          state.payStatus = 2;
        }
      }
      state.showPayTyoe = true;
    };
    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    const modifyPhone = async () => {
      // window.location.href = './modifyphone';
      router.push({ path: './modifyphone' });
    };
    const appclick = () => {
      state.showDetail = !state.showDetail;
      nftUtils.setcnzzRecordByBtn('详情显示看漫简介');
    };
    const handleLogout = async () => {
      // const { resultCode } = await logout();
      // if (resultCode == 200) {
      //   setLocal('token', '');
      //   window.location.href = '/home';
      // }
    };

    const timeChange = (time, format = 'YYYY-MM-DD') => {
      return moment(time).format(format);
    };
    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('../imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('../imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('../imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('../imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('../imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('../imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    const lookMore = () => {
      state.lookMoreFlag = !state.lookMoreFlag;
    };

    // 获取格式时间
    const getTime = (time, format = 'MM月DD日 HH:mm') => {
      return moment(time).format(format);
    };

    return {
      ...toRefs(state),
      modifyPhone,
      handleLogout,
      goToDetail,
      levelsChange,
      timeChange,
      next,
      appclick,
      getDetail,
      diffTime,
      getBox,
      lookMore,
      getTime,
      icon_tc_gb: require('@/imgs/icon/icon_tc_gb.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.newItem42 {
  font-size: 0px;
  margin-bottom: 24px;
  img {
    width: 343px;
  }
}
.newItem38 {
  font-size: 0px;
  margin-bottom: 24px;
  position: relative;
  img {
    width: 343px;
    height: 217px;
  }
  .desc {
    position: absolute;
    top: 14px;
    right: 9px;
    width: 52px;
    height: 24px;
    background: #2f293f;
    font-size: 12px;
    border-radius: 17px 17px 17px 17px;
    border: 1px solid #ffffff;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.van-action-sheet {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    background-color: $bc;
    padding-top: 3px;

    .img-zs {
      height: 16px;
    }
    .icon_tc_gb {
      margin-left: 300px;
      width: 16px;
      height: 16px;
    }
    .img-title {
      height: 16px;
    }
    span {
      font-size: 20px;
      color: $fc;
      margin-top: -20px;
    }
  }
  .block {
    height: 80px;
    background-color: $bc;
  }
  .content-van {
    background-color: $bc;
    color: $fc;
    font-size: 14px;

    .nei {
      margin-left: 16px;
      margin-right: 16px;

      color: rgba(255, 255, 255, 0.7);
      .nei-title {
        color: rgba(255, 255, 255, 1);
      }
      span {
        color: #3cdbe8;
      }
      p {
        margin: 0px;
        margin-bottom: 40px;
      }
    }
  }
}

.drawBox {
  padding: 3px;
  background: #2f293f;
  margin-bottom: 25px;

  .drawKnow {
    border: 1px solid #867a6c;

    .titlePic {
      padding-top: 10px;
      position: relative;
      img {
        width: 301px;
      }
      .desc {
        position: absolute;
        top: 14px;
        right: 9px;
        width: 52px;
        height: 24px;
        background: #2f293f;
        font-size: 12px;
        border-radius: 17px 17px 17px 17px;
        border: 1px solid #ffffff;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }

    .stepItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 36px;
      .stepTitle {
        font-weight: 600;
        width: 90px;
        text-align: right;
      }

      .stepTime {
        white-space: nowrap;
      }

      .stepIconBox {
        padding: 0 20px;
        font-size: 0;
        position: relative;

        .stepLine {
          width: 2px;
          height: 30px;
          position: absolute;
          bottom: 17px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .stepIcon {
        width: 14px;
        height: 14px;
      }
    }

    .stepItem:last-child {
      margin-bottom: 24px;
    }
  }
}
.seting-box {
  position: relative;
  box-sizing: border-box;
  .image-box {
    margin-bottom: 19px;
  }
  .icon-logo {
    width: 16px;
    margin-right: 4px;
    font-size: 0px;
  }
  .l-h {
    line-height: 14px;
  }
  .primary-color {
    color: $primary;
  }
  .name-box {
    display: flex;
    align-items: center;
  }
  .app {
    margin: 6px 0;
    display: flex;
    align-items: center;
  }
  .wid237 {
    width: 237px;
  }
  .flex-bt {
    @include fj();
  }
  .marg36 {
    margin-bottom: 26px;
  }
  .card-box {
    padding: 25px 0px 29px;
    @include bis('../imgs/comm/Slice8.png');
    margin-bottom: 24px;
  }
  .card-msg {
    width: 246px;
    margin-bottom: 20px;
  }
  .imgcard {
    width: 180px;
  }
  .wid90 {
    width: 90px;
    height: 100px;
  }
  .wid73 {
    width: 50px;
    font-size: 0px;
  }
  .brigt {
    padding: 24px 54px 38px;
    @include bis('../imgs/comm/Slice9.png');
    margin-bottom: 24px;
  }
  .btnbox {
    position: fixed;
    bottom: 76px; // 19px;
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    z-index: 99;
    font-size: 16px;
  }
  .btn_bg {
    width: 268px;
    height: 45px;
    background-image: url('../imgs/comm/pic_button_cmh.png');

    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-size: 100% 100%;
    border-radius: 31px;
    font-size: 16px;
    color: #1c172a;
    z-index: 10;
  }
  .title {
    margin-bottom: 19px;
    font-size: 0px;
  }
  .f-0 {
    font-size: 0px;
  }
  .brigt-content {
    margin-bottom: 24px;
  }
  .bright-item {
    @include fj();
    padding-bottom: 6px;
    margin-top: 10px;
    border-bottom: 1px solid #494359;
  }
  .parameter {
    @include bis('../imgs/comm/Slice9.png');
    padding: 10px 21px 27px;
  }
  .bright {
    @include bis('../imgs/comm/Slice8.png');
    width: 343px;
    height: 317px;
    margin-bottom: 24px;
  }

  .bgBox {
    padding: 3px;
    background: #2f293f;
    margin-top: 25px;
  }
  .buyKnow {
    // @include bis('../imgs/comm/buyKnowBg.png');
    border: 1px solid #867a6c;
    padding-bottom: 10px;
    // margin-top: 24px;

    .titlePic {
      padding-top: 10px;
      img {
        width: 237px;
      }
    }

    .text {
      padding: 0 20px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
    }
  }

  .countBox {
    background: #2f293f;
    margin-bottom: 24px;
    padding: 0 18px;
    .countPic {
      padding-top: 10px;
      position: relative;
      img {
        width: 266px;
      }
      .questionIcon {
        position: absolute;
        padding: 5px;
        top: 40%;
        transform: translateY(-50%);
        right: 0;
      }
    }

    .countItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 36px;
      border-bottom: 1px solid #494359;

      .name,
      .num {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
      }

      .num {
        color: #3cdbe8;
      }
    }

    .lookMore {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 0 10px 0;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;

      div {
        display: inline-block;
      }

      img {
        width: 16px;
        transform: rotate(90deg);
      }

      .lookMoreActive {
        transform: rotate(270deg);
      }
    }
  }
  .pic-box {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    //background: rgba(0, 0, 0, .5);
    background-blend-mode: multiply;
    padding: 13px 25px 14px 34px;
    margin-bottom: 24px;
  }
  .other-product-box {
    @include fj();
    align-items: center;
  }

  .indexInner {
    // background: rgba(0,0,0,0.8);
    // height: 100%;
    // width: 100%;
    // position: absolute;
    // left: 0;
    // top: 0;
    // z-index: 2;
  }
  .num-lable {
    background: #1c172a;
    border-radius: 3px 0px 0px 3px;
    border: 1px solid rgba($color: #fff, $alpha: 0.5);
    padding: 0 4px;
    border-right: none;
  }
  .num-value {
    background: linear-gradient(64deg, #ffffff 0%, #d6f0ff 100%);
    color: #002375;
    border: 1px solid rgba($color: #fff, $alpha: 0.5);
    border-left: none;
    padding: 0 4px;
    border-radius: 0px 3px 3px 0px;
  }
  .c-07 {
    color: rgba(255, 255, 255, 0.7);
  }
  .num-box {
    box-sizing: border-box;
    margin-bottom: 14px;
  }
  .prudct-box {
    background: #2f293f;
    padding: 9px 18px;
    margin: 4px 0 21px;
  }
  .prudct {
    @include fj();
    align-items: center;
  }
  //position: relative;
  .content {
    padding: 0 16px 150px;
    box-sizing: border-box;
    position: absolute;
    top: 247px;
    max-width: 100vw;
  }
  .meng {
    width: 100vw;
    height: 160px;
    position: absolute;
    top: 240px;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1c172a 75%);
  }
  .next-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #2f293f;

    font-weight: 600;
  }
  .next {
    @include bis('../imgs/comm/Slice5.png');
    opacity: 1;
    padding: 0px 12px;
  }
  .f-16 {
    font-size: 16px;
  }
  .f-w {
    font-weight: bold;
  }
  .f-30 {
    font-size: 30px;
  }
  .f-14 {
    font-size: 14px;
  }
  .f-20 {
    font-size: 20px;
  }
  .t-c {
    text-align: center;
  }
  .f-10 {
    font-size: 10px;
  }
  .f-12 {
    font-size: 12px;
  }
  .f-24 {
    font-size: 24px;
  }
  .f-9 {
    font-size: 9px;
  }
}
.failPopup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .head {
    padding: 16px 25px 20px 25px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }

  .descContent {
    padding: 0 25px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }

    > div {
      margin-bottom: 20px;
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
</style>
